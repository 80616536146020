import { FC } from "react";

import { classNames } from "../utils/classNames";
import { SectionProps } from "./types";

export const BrokerCheck: FC<SectionProps> = ({ className }) => {
  return (
    <footer
      className={classNames(
        "text-frecBlack bg-frecDarkBeige grid grid-cols-[auto_minmax(0,_86rem)_auto] text-center",
        className,
      )}
    >
      <div className="">{/* empty */}</div>
      <div
        data-nosnippet
        className="dotted-v relative flex flex-col-reverse px-6 py-8 pb-9 lg:mx-12 lg:border-x lg:px-16"
      >
        <p className="text-frecXDarkGray">
          Check the background of Frec Securities LLC on{" "}
          <a
            href="https://brokercheck.finra.org/firm/summary/316641"
            className="hover:text-frecBlack underline"
            rel="noreferrer"
            target="_blank"
          >
            FINRA’s BrokerCheck
          </a>
          .
        </p>
      </div>
      <div className="">{/* empty */}</div>
    </footer>
  );
};
